import { ReactElement } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { selectFailureIds } from '../../../redux/slices/dashboardSlice';
import SuccessCard from '../SuccessCard/SuccessCard';

const FileStatusCard = (): ReactElement => {
  const failureFileIds = useAppSelector(selectFailureIds);

  const failureCount = failureFileIds.length;

  const hasFailure = failureCount > 0;

  if (!hasFailure) {
    return <SuccessCard message="Files Uploaded" />;
  } else {
    return <></>;
  }
};

export default FileStatusCard;
